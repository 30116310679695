import React from 'react'
import { Link } from 'react-router-dom'

import { Player } from '@lottiefiles/react-lottie-player'
import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Features from '../components/features'
import TechStack1 from '../components/tech-stack1'
import Accordion from '../components/accordion'
import Includes from '../components/includes'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>
          HintEd Smart Manuals. Easily create interactive how-to guides
        </title>
        <meta
          name="description"
          content="Empower your digital training with HintEd. Create interactive user manuals, track user engagement, and analyze their understanding with ease. Enhance the user experience now."
        />
        <meta
          property="og:title"
          content="HintEd Smart Manuals. Easily create interactive how-to guides"
        />
        <meta
          property="og:description"
          content="Empower your digital training with HintEd. Create interactive user manuals, track user engagement, and analyze their understanding with ease. Enhance the user experience now."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/59e42bed-5356-449a-8b07-54167279a332/30a33e3f-38bd-4c8b-94ad-cab6f9bb74ce?org_if_sml=1"
        />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name"></Navbar>
      <section className="home-section">
        <div className="home-hero">
          <div className="home-content">
            <main className="home-main">
              <header className="home-header">
                <h1 className="home-heading">
                  Transform your user manuals into engaging and interactive
                  experiences!
                </h1>
                <span className="home-caption">
                  Sign up now and start creating stunning step-by-step how-to
                  guides with screenshots in seconds
                </span>
              </header>
              <div className="home-buttons">
                <a
                  href="https://chrome.google.com/webstore/detail/hinted-smart-manuals-edit/fndkgbgkmmoblbhlbdkacjcbngdlokpi"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link"
                >
                  <div className="home-get-started button">
                    <span className="home-text">Get started</span>
                  </div>
                </a>
                <a
                  href="https://app.hinted.me/signup?plan=smartManualsStart"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link1"
                >
                  <div className="home-get-started1 button">
                    <span className="home-text01">Get started</span>
                  </div>
                </a>
                <a href="#features" className="home-link2">
                  <div className="home-get-started2 button">
                    <span className="home-text02">View features</span>
                  </div>
                </a>
              </div>
            </main>
          </div>
          <div className="home-image">
            <img
              alt="image"
              src="/playground_assets/6-1500h.png"
              className="home-image1"
            />
          </div>
          <div className="home-image2">
            <img
              alt="image"
              src="/playground_assets/6-1500h.png"
              className="home-image3"
            />
          </div>
        </div>
      </section>
      <section className="home-section01">
        <h2 className="home-text03">
          Turn manual creation into a fun and effortless task!
        </h2>
        <Features rootClassName="features-root-class-name"></Features>
      </section>
      <section className="tech-stack">
        <TechStack1 rootClassName="tech-stack1-root-class-name"></TechStack1>
      </section>
      <section id="benefits" className="home-section03">
        <header className="home-header1">
          <header className="home-left">
            <span className="section-head">WHY US?</span>
            <h2 className="section-heading">
              Here are four ways HintEd can help:
            </h2>
          </header>
          <div className="home-right">
            <p className="home-paragraph section-description">
              Technology has become an integral part of modern enterprises, but
              with its rapid pace of innovation, it can be challenging to keep
              up. That&apos;s where HintEd comes in.
            </p>
          </div>
        </header>
        <div className="home-stats">
          <div className="home-stat">
            <h1 className="stats.header">2X</h1>
            <span className="stats.subheader">Faster Adoption</span>
            <span className="stats.text">
              HintEd provides step-by-step guides, real-time feedback, and user
              behavior analytics to help users understand and retain information
              better.
            </span>
          </div>
          <div className="home-stat1">
            <h1 className="stats.header home-heading03">40%</h1>
            <span className="stats.subheader home-text06">
              Better user engagement
            </span>
            <span className="stats.text">
              Our platform is designed to be personalized and interactive. This
              helps ensure maximum utilization of technology.
            </span>
          </div>
          <div className="home-stat2">
            <h1 className="stats.header home-text08">20</h1>
            <span className="stats.subheader home-text09">
              Saved training hours
            </span>
            <span className="stats.text">
              And reduced cost of training materials by providing employees with
              personalized, accessible, and up-to-date information.
            </span>
          </div>
          <div className="home-stat3">
            <h1 className="stats.header home-text11">30%</h1>
            <span className="stats.subheader home-text12">
              Support costs reduction
            </span>
            <span className="stats.text">
              By providing users with real-time, context-aware support, HintEd
              helps companies reduce the volume of support requests, allowing
              support teams to focus on more complex issues and improving the
              overall support experience.
            </span>
          </div>
        </div>
      </section>
      <section className="home-section05">
        <div className="home-note">
          <div className="home-image4">
            <Player
              src="https://smartmanuals.hinted.me/kWo8UzdtB5.json"
              loop
              speed="1"
              autoplay
              background="transparent"
              className="home-lottie-node"
            ></Player>
          </div>
          <div className="home-content1">
            <div className="home-caption1">
              <span className="section-head">NOW THE FAQ SECTION</span>
            </div>
            <div className="home-heading04">
              <div className="home-header2">
                <h2 className="section-heading">
                  So, what&apos;s the deal with us?
                </h2>
              </div>
              <Accordion rootClassName="accordion-root-class-name"></Accordion>
            </div>
          </div>
        </div>
      </section>
      <section className="home-section07">
        <main className="home-banner">
          <div className="home-header3">
            <h2 className="section-heading">
              HintEd enhances the creation of user manuals, making it a fun and
              interactive process
            </h2>
          </div>
          <div className="home-buttons1">
            <a
              href="https://app.hinted.me/signup?plan=smartManualsStart"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link3"
            >
              <div className="home-get-started3 button">
                <span className="home-text14">
                  Get started, it&apos;s free!
                </span>
              </div>
            </a>
          </div>
        </main>
      </section>
      <section id="prices" className="home-section08">
        <main className="home-pricing">
          <header className="home-header4">
            <header className="home-left1">
              <span className="section-head">Pricing</span>
              <h2 className="section-heading home-heading07">
                Start small, think big
              </h2>
            </header>
            <div className="home-right1">
              <p className="home-paragraph1 section-description">
                Choose the plan that fits your needs: from the free plan for
                individuals and small businesses, to the professional plan with
                advanced features, to the enterprise plan for large
                organizations.
              </p>
            </div>
          </header>
          <div className="home-plans-container">
            <main className="home-plans">
              <div className="home-plan">
                <div className="home-details">
                  <div className="home-header5">
                    <label className="home-name">Start</label>
                    <div className="home-pricing1">
                      <h1 className="home-price">FREE</h1>
                    </div>
                  </div>
                  <p className="home-description">
                    Good for for individuals and small businesses looking for an
                    efficient and user-friendly solution for creating
                    interactive user manuals
                  </p>
                </div>
                <div className="home-buy-details">
                  <a
                    href="https://chrome.google.com/webstore/detail/hinted-smart-manuals-edit/fndkgbgkmmoblbhlbdkacjcbngdlokpi"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link4"
                  >
                    <div className="home-buy button">
                      <span className="home-text15">
                        <span>Start Basic</span>
                        <br></br>
                      </span>
                    </div>
                  </a>
                  <div className="home-features">
                    <span className="home-heading08">You will get</span>
                    <div className="home-list">
                      <Includes
                        Label="Unlimited Smart Manuals"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                      <Includes
                        Label="Unlimited plays"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                      <Includes
                        Label="Unlimited steps count"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                      <Includes
                        Label="Unlimited users"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                      <Includes
                        Label="Sharing via hyperlink"
                        rootClassName="includes-root-class-name21"
                      ></Includes>
                      <Includes
                        Label="Creating Smart Manuals for web applications"
                        rootClassName="includes-root-class-name22"
                      ></Includes>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-plan1">
                <div className="home-details1">
                  <div className="home-header6">
                    <label className="home-name1">Pro</label>
                    <div className="home-pricing2">
                      <h1 className="home-price1">$16</h1>
                      <span className="home-duration">.80</span>
                      <span className="home-duration1">/mo</span>
                    </div>
                  </div>
                  <p className="home-description1">
                    The professional plan offers advanced features and expanded
                    capabilities for businesses seeking a more robust and
                    comprehensive solution for creating engaging and interactive
                    manuals
                  </p>
                </div>
                <div className="home-buy-details1">
                  <a
                    href="https://chrome.google.com/webstore/detail/hinted-smart-manuals-edit/fndkgbgkmmoblbhlbdkacjcbngdlokpi"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link5"
                  >
                    <div className="home-buy1 button">
                      <span className="home-text18">
                        <span>Start Professional</span>
                        <br></br>
                      </span>
                    </div>
                  </a>
                  <div className="home-features1">
                    <span className="home-heading09">
                      You will get everything in Basic, plus
                    </span>
                    <div className="home-list1">
                      <Includes
                        Label="Custom design and branding"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                      <Includes
                        Label="Sensitive info bluring"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                      <Includes
                        Label="Export to PDF or Word"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                      <Includes
                        Label="Custom setting for the shared access"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                      <Includes
                        Label="Embed code"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                      <Includes
                        Label="Creating Smart Manuals for Windows applications"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-plan2">
                <div className="home-details2">
                  <div className="home-header7">
                    <label className="home-name2">Enterprise</label>
                    <div className="home-pricing3">
                      <span className="home-duration2">Custom pricing</span>
                    </div>
                  </div>
                  <p className="home-description2">
                    Extensive customization options, dedicated support, and
                    scalable solutions for large organizations seeking to
                    streamline their manual creation process and enhance their
                    overall brand experience
                  </p>
                </div>
                <div className="home-buy-details2">
                  <Link to="/contact-us" className="home-navlink">
                    <div className="home-buy2 button">
                      <span className="home-text21">
                        <span>Contact us</span>
                        <br></br>
                      </span>
                    </div>
                  </Link>
                  <div className="home-features2">
                    <span className="home-heading10">
                      You will get everything in professional, plus
                    </span>
                    <div className="home-list2">
                      <Includes
                        Label="User testing and digital skills evaluation"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                      <Includes
                        Label="Multiple workspaces"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                      <Includes
                        Label="Export to SCORM"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                      <Includes
                        Label="Advanced security"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                      <Includes
                        Label="On-premises option"
                        rootClassName="includes-root-class-name"
                      ></Includes>
                      <Includes
                        Label="Dedicated support manager"
                        rootClassName="includes-root-class-name23"
                      ></Includes>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </main>
      </section>
      <section className="home-section10">
        <main className="home-content2">
          <header className="home-header8">
            <h2 className="home-heading11 section-heading">
              Say goodbye to tedious manual writing and hello to more time for
              innovation. Try HintEd today!
            </h2>
            <div className="home-buttons2">
              <a
                href="https://chrome.google.com/webstore/detail/hinted-smart-manuals-edit/fndkgbgkmmoblbhlbdkacjcbngdlokpi"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link6"
              >
                <div className="home-extension button">
                  <svg
                    viewBox="0 0 1079.4422857142856 1024"
                    className="home-icon"
                  >
                    <path d="M510.286 0c87.429-0.571 176.571 21.714 257.714 68.571 89.714 52 157.714 126.857 201.143 212.571l-424-22.286c-120-6.857-234.286 60.571-273.714 173.714l-157.714-242.286c98.286-122.286 245.714-189.714 396.571-190.286zM83.429 231.429l192.571 378.857c54.286 106.857 169.143 172 288 149.714l-131.429 257.714c-245.143-37.714-432.571-249.714-432.571-505.714 0-103.429 30.857-200 83.429-280.571zM989.714 328c89.714 230.857 0 499.429-221.714 627.429-89.714 52-188.571 73.143-284.571 68l231.429-356c65.714-101.143 64.571-233.143-13.714-324.571zM512 339.429c95.429 0 172.571 77.143 172.571 172.571s-77.143 172.571-172.571 172.571-172.571-77.143-172.571-172.571 77.143-172.571 172.571-172.571z"></path>
                  </svg>
                  <span className="home-text24">
                    Download for Chrome for free
                  </span>
                </div>
              </a>
            </div>
          </header>
          <img
            alt="image"
            src="/playground_assets/5-1200w.png"
            className="home-image5"
          />
        </main>
      </section>
      <Footer rootClassName="footer-root-class-name1"></Footer>
      <div>
        <DangerousHTML
          html={`<script>
    /*
Accordion - Code Embed
*/

/* listenForUrlChangesAccordion() makes sure that if you changes pages inside your app,
the Accordions will still work*/

const listenForUrlChangesAccordion = () => {
      let url = location.href;
      document.body.addEventListener(
        "click",
        () => {
          requestAnimationFrame(() => {
            if (url !== location.href) {
              runAccordionCodeEmbed();
              url = location.href;
            }
          });
        },
        true
      );
    };


const runAccordionCodeEmbed = () => {
    const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
    const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
    const accordionIcons = document.querySelectorAll('[data-role="accordion-icon"]'); // All accordion icons

    accordionContents.forEach((accordionContent) => {
        accordionContent.style.display = "none"; //Hides all accordion contents
    });

    accordionContainers.forEach((accordionContainer, index) => {
        accordionContainer.addEventListener("click", () => {
            accordionContents.forEach((accordionContent) => {
            accordionContent.style.display = "none"; //Hides all accordion contents
            });

            accordionIcons.forEach((accordionIcon) => {
                accordionIcon.style.transform = "rotate(0deg)"; // Resets all icon transforms to 0deg (default)
            });

            accordionContents[index].style.display = "flex"; // Shows accordion content
            accordionIcons[index].style.transform = "rotate(180deg)"; // Rotates accordion icon 180deg
        });
    });
}

runAccordionCodeEmbed()
listenForUrlChangesAccordion()

/*
Here's what the above is doing:
    1. Selects all accordion containers, contents, and icons
    2. Hides all accordion contents
    3. Adds an event listener to each accordion container
    4. When an accordion container is clicked, it:
        - Hides all accordion contents
        - Resets all icon transforms to 0deg (default)
        - Checks if this container has class "accordion-open"
            - If it does, it removes class "accordion-open"
            - If it doesn't, it:
                - Removes class "accordion-open" from all containers
                - Adds class "accordion-open" to this container
                - Shows accordion content
                - Rotates accordion icon 180deg
*/
</script>`}
        ></DangerousHTML>
      </div>
    </div>
  )
}

export default Home
