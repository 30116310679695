import React from 'react'
import { Link } from 'react-router-dom'

import DangerousHTML from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  return (
    <nav className={`navbar-navbar ${props.rootClassName} `}>
      <img
        alt="HintEd logo"
        src={props.BrandingLogo}
        className="navbar-branding-logo"
      />
      <div className="navbar-nav-content">
        <div className="navbar-nav-links">
          <a href="#features" className="navbar-link nav-link">
            Features
          </a>
          <a href="#benefits" className="navbar-link01 nav-link">
            Why us
          </a>
          <a href="#prices" className="navbar-link02 nav-link">
            Prices
          </a>
          <Link to="/contact-us" className="navbar-link03 nav-link">
            Contact
          </Link>
        </div>
        <a
          href="https://chrome.google.com/webstore/detail/hinted-smart-manuals-edit/fndkgbgkmmoblbhlbdkacjcbngdlokpi"
          target="_blank"
          rel="noreferrer noopener"
          className="navbar-link04"
        >
          <div className="navbar-get-started get-started">
            <span className="navbar-text">Get started</span>
          </div>
        </a>
        <div id="open-mobile-menu" className="navbar-hamburger get-started">
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="navbar-image"
          />
        </div>
      </div>
      <div id="mobile-menu" className="navbar-mobile-menu">
        <div className="navbar-branding">
          <img
            alt={props.image_alt1}
            src={props.image_src1}
            className="navbar-image1"
          />
          <div id="close-mobile-menu" className="navbar-container">
            <svg viewBox="0 0 1024 1024" className="navbar-icon">
              <path
                d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
        <div className="navbar-nav-links1">
          <a href="#features" className="navbar-link05 nav-link">
            Features
          </a>
          <a href="#benefits" className="navbar-link06 nav-link">
            Why us
          </a>
          <a href="#prices" className="navbar-link07 nav-link">
            Prices
          </a>
          <Link to="/contact-us" className="navbar-link08 nav-link">
            Contact
          </Link>
        </div>
        <a
          href="https://app.hinted.me/signup?plan=smartManualsStart"
          target="_blank"
          rel="noreferrer noopener"
          className="navbar-link09"
        >
          <div className="navbar-get-started1 get-started">
            <span className="navbar-text1">Get started</span>
          </div>
        </a>
      </div>
      <div className="">
        <DangerousHTML
          html={`<script>
      /*
  Mobile menu - Code Embed
  */

  /* listenForUrlChangesMobileMenu() makes sure that if you changes pages inside your app,
  the mobile menu will still work*/

  const listenForUrlChangesMobileMenu = () => {
      let url = location.href;
      document.body.addEventListener("click", () => {
          requestAnimationFrame(() => {
              if (url !== location.href) {
                  runMobileMenuCodeEmbed();
                  url = location.href;
              }
          });
      },
      true
      );
  };

  const runMobileMenuCodeEmbed = () => {
      // Mobile menu
      const mobileMenu = document.querySelector("#mobile-menu")

      // Buttons
      const closeButton = document.querySelector("#close-mobile-menu")
      const openButton = document.querySelector("#open-mobile-menu")
      const navLinks = document.getElementsByClassName("nav-link")



      // On openButton click, set the mobileMenu position left to -100vw
      openButton && openButton.addEventListener("click", function() {
          mobileMenu.style.transform = "translateX(0%)"
      })

      // On closeButton click, set the mobileMenu position to 0vw
      closeButton && closeButton.addEventListener("click", function() {
          mobileMenu.style.transform = "translateX(100%)"
      })

      // On navlink click, set the mobileMenu position to 0vw
      for (const navLink of navLinks) {
  navLink.addEventListener('click', function handleClick(event) {
    console.log('link clicked', event);
    mobileMenu.style.transform = "translateX(100%)"
  });
}
  }

  runMobileMenuCodeEmbed()
  listenForUrlChangesMobileMenu()
</script>
`}
          className=""
        ></DangerousHTML>
      </div>
    </nav>
  )
}

Navbar.defaultProps = {
  image_alt: 'image',
  image_src1: '/playground_assets/hinted-logo_10_04_pink.svg',
  image_src: '/playground_assets/hamburger-200h.png',
  image_alt1: 'image',
  rootClassName: '',
  BrandingLogo: '/playground_assets/hinted-logo_10_04_all_white.svg',
}

Navbar.propTypes = {
  image_alt: PropTypes.string,
  image_src1: PropTypes.string,
  image_src: PropTypes.string,
  image_alt1: PropTypes.string,
  rootClassName: PropTypes.string,
  BrandingLogo: PropTypes.string,
}

export default Navbar
