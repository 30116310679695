import React from 'react'
import { Link } from 'react-router-dom'

import DangerousHTML from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './navbar1.css'

const Navbar1 = (props) => {
  return (
    <nav className={`navbar1-navbar ${props.rootClassName} `}>
      <Link to="/" className="navbar1-navlink">
        <img
          alt="HintEd logo"
          src={props.BrandingLogo}
          className="navbar1-branding-logo"
        />
      </Link>
      <div className="navbar1-nav-content">
        <a
          href="https://app.hinted.me/signup?plan=smartManualsStart"
          target="_blank"
          rel="noreferrer noopener"
          className="navbar1-link"
        >
          <div className="navbar1-get-started get-started">
            <span className="navbar1-text">Get started</span>
          </div>
        </a>
        <div id="open-mobile-menu" className="navbar1-hamburger get-started">
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="navbar1-image"
          />
        </div>
      </div>
      <div id="mobile-menu" className="navbar1-mobile-menu">
        <div className="navbar1-branding">
          <img
            alt={props.image_alt1}
            src={props.image_src1}
            className="navbar1-image1"
          />
          <div id="close-mobile-menu" className="navbar1-container">
            <svg viewBox="0 0 1024 1024" className="navbar1-icon">
              <path
                d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
        <div className="navbar1-nav-links">
          <span className="nav-link">Features</span>
          <span className="nav-link">Why us</span>
          <span className="nav-link">Prices</span>
          <span className="nav-link">Contact</span>
        </div>
        <div className="get-started">
          <span className="navbar1-text1">Get started</span>
        </div>
      </div>
      <div className="">
        <DangerousHTML
          html={`<script>
    /*
Mobile menu - Code Embed
*/

/* listenForUrlChangesMobileMenu() makes sure that if you changes pages inside your app, 
the mobile menu will still work*/

const listenForUrlChangesMobileMenu = () => {
    let url = location.href;
    document.body.addEventListener("click", () => {
        requestAnimationFrame(() => {
            if (url !== location.href) {
                runMobileMenuCodeEmbed();
                url = location.href;
            }
        });
    },
    true
    );
};

const runMobileMenuCodeEmbed = () => {
    // Mobile menu
    const mobileMenu = document.querySelector("#mobile-menu")

    // Buttons
    const closeButton = document.querySelector("#close-mobile-menu")
    const openButton = document.querySelector("#open-mobile-menu")

    // On openButton click, set the mobileMenu position left to -100vw
    openButton && openButton.addEventListener("click", function() {
        mobileMenu.style.transform = "translateX(0%)"
    })

    // On closeButton click, set the mobileMenu position to 0vw
    closeButton && closeButton.addEventListener("click", function() {
        mobileMenu.style.transform = "translateX(100%)"
    })
}

runMobileMenuCodeEmbed()
listenForUrlChangesMobileMenu()
</script>`}
          className=""
        ></DangerousHTML>
      </div>
    </nav>
  )
}

Navbar1.defaultProps = {
  image_alt: 'image',
  BrandingLogo: '/playground_assets/hinted-logo_10_04_all_white.svg',
  rootClassName: '',
  image_src: '/playground_assets/hamburger-200h.png',
  image_alt1: 'image',
  image_src1: 'https://play.teleporthq.io/static/svg/placeholders/no-image.svg',
}

Navbar1.propTypes = {
  image_alt: PropTypes.string,
  BrandingLogo: PropTypes.string,
  rootClassName: PropTypes.string,
  image_src: PropTypes.string,
  image_alt1: PropTypes.string,
  image_src1: PropTypes.string,
}

export default Navbar1
