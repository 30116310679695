import React from 'react'

import PropTypes from 'prop-types'

import './accordion.css'

const Accordion = (props) => {
  return (
    <div className={`accordion-accordion ${props.rootClassName} `}>
      <div data-role="accordion-container" className="accordion-element">
        <div className="accordion-details">
          <span className="accordion-text">{props.text}</span>
          <span data-role="accordion-content" className="accordion-text1">
            {props.text1}
          </span>
        </div>
        <svg
          viewBox="0 0 1024 1024"
          data-role="accordion-icon"
          className="accordion-icon"
        >
          <path
            d="M366 708l196-196-196-196 60-60 256 256-256 256z"
            className=""
          ></path>
        </svg>
      </div>
      <div
        data-role="accordion-container"
        className="accordion-element1 accordion-element"
      >
        <div className="accordion-details1">
          <span className="accordion-text2">{props.text2}</span>
          <span data-role="accordion-content" className="accordion-text3">
            {props.text3}
          </span>
        </div>
        <svg
          viewBox="0 0 1024 1024"
          data-role="accordion-icon"
          className="accordion-icon2"
        >
          <path
            d="M366 708l196-196-196-196 60-60 256 256-256 256z"
            className=""
          ></path>
        </svg>
      </div>
      <div
        data-role="accordion-container"
        className="accordion-element2 accordion-element"
      >
        <div className="accordion-details2">
          <span className="accordion-text4">{props.text4}</span>
          <span data-role="accordion-content" className="accordion-text5">
            {props.text5}
          </span>
        </div>
        <svg
          viewBox="0 0 1024 1024"
          data-role="accordion-icon"
          className="accordion-icon4"
        >
          <path
            d="M366 708l196-196-196-196 60-60 256 256-256 256z"
            className=""
          ></path>
        </svg>
      </div>
    </div>
  )
}

Accordion.defaultProps = {
  text2: 'How do HintEd Smart Manuals work?',
  text1:
    'HintEd Smart Manuals are interactive and personalized learning materials that guide users through the adoption process of new technologies. They are designed to make technology adoption easier, faster and more effective by providing step-by-step instructions, real-time feedback, and user behavior analytics.',
  text: 'What are HintEd Smart Manuals?',
  text4: 'How do HintEd Smart Manuals improve technology adoption?',
  text5:
    'HintEd Smart Manuals are designed to improve technology adoption by providing an interactive and personalized learning experience. The manuals break down complex technology into simple, easy-to-follow steps and provide real-time feedback to users. This helps users understand and retain information better, leading to a faster and more effective technology adoption process. The manuals also provide actionable insights to organizations, allowing them to measure and adjust their adoption strategies to achieve better results. By improving technology adoption, HintEd Smart Manuals help organizations maximize the return on their technology investment.',
  text3:
    'HintEd Smart Manuals work by providing a seamless learning experience to users. They are customized to fit the individual needs and learning styles of each user, ensuring that every step of the technology adoption process is well understood. The manuals include interactive elements such as videos, quizzes, and simulations that make learning engaging and fun.',
  rootClassName: '',
}

Accordion.propTypes = {
  text2: PropTypes.string,
  text1: PropTypes.string,
  text: PropTypes.string,
  text4: PropTypes.string,
  text5: PropTypes.string,
  text3: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Accordion
