import React from 'react'

import DangerousHTML from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './hubspot-form.css'

const HubspotForm = (props) => {
  return (
    <div className={` ${props.rootClassName} `}>
      <div className="">
        <DangerousHTML
          html={`<script charset="utf-8" type="text/javascript" src="https://js.hsforms.net/forms/embed/v2.js"></script>

<script>
  (() => {
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";

    script.addEventListener("load", () => {
      hbspt.forms.create({
        region: "na1",
    portalId: "4244155",
    formId: "f0623854-095e-4833-afe1-759ea6a0eef0",
      });
    });

    document.head.append(script);
  })();
</script>`}
          className=""
        ></DangerousHTML>
      </div>
    </div>
  )
}

HubspotForm.defaultProps = {
  rootClassName: '',
}

HubspotForm.propTypes = {
  rootClassName: PropTypes.string,
}

export default HubspotForm
