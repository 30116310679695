import React from 'react'

import DangerousHTML from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './features.css'

const Features = (props) => {
  return (
    <section
      id="features"
      className={`features-schedule ${props.rootClassName} `}
    >
      <div className="features-content">
        <div className="features-main">
          <div className="features-tab-selector">
            <div
              data-role="schedule-select"
              className="features-tab tab-active tab"
            >
              <span className="features-text">{props.text1}</span>
            </div>
            <div data-role="schedule-select" className="features-tab1 tab">
              <span className="features-text1">{props.text2}</span>
            </div>
            <div data-role="schedule-select" className="features-tab2 tab">
              <span className="features-text2">{props.text3}</span>
            </div>
          </div>
          <div className="features-lists">
            <section data-role="schedule-content" className="features-list">
              <section className="features-note">
                <div className="features-image">
                  <img
                    alt={props.image_alt}
                    src={props.image_src}
                    className="features-image1"
                  />
                </div>
                <div className="features-content1">
                  <div className="features-main1">
                    <div className="features-heading">
                      <h2 className="section-heading">{props.Heading}</h2>
                      <p className="section-description">{props.Paragraph}</p>
                    </div>
                  </div>
                </div>
              </section>
            </section>
            <section data-role="schedule-content" className="features-list1">
              <section className="features-note1">
                <div className="features-image2">
                  <img
                    alt={props.image_alt1}
                    src={props.image_src1}
                    className="features-image3"
                  />
                </div>
                <div className="features-content2">
                  <div className="features-main2">
                    <div className="features-heading2">
                      <h2 className="section-heading">
                        Share manuals seamlessly. Anywhere!
                      </h2>
                      <p className="section-description">
                        HintEd smart manuals can be shared in various formats
                        including links, PDF, DOC, and SCORM. This versatility
                        ensures that you can share your manual with anyone,
                        anywhere, and on any device
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </section>
            <section data-role="schedule-content" className="features-list2">
              <section className="features-note2">
                <div className="features-image4">
                  <img
                    alt={props.image_alt11}
                    src={props.image_src11}
                    className="features-image5"
                  />
                </div>
                <div className="features-content3">
                  <div className="features-main3">
                    <div className="features-heading4">
                      <h2 className="section-heading">
                        Track and Analyze User Engagement with HintEd
                      </h2>
                      <p className="section-description">
                        <span className="">
                          With HintEd, not only can you track and analyze how
                          users interact with your manuals, but you can also
                          perform tests to gauge their understanding of the
                          functionality. Get valuable insights and measure the
                          effectiveness of your training.
                        </span>
                        <br className=""></br>
                        <br className=""></br>
                        <br className=""></br>
                        <br className=""></br>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </div>
          <div className="">
            <DangerousHTML
              html={`<script>
// Get all tab buttons
const tabButtons = document.querySelectorAll('[data-role="schedule-select"]');
// Get all tab contents
const tabContents = document.querySelectorAll('[data-role="schedule-content"]');

// Add "tab-active" class to the first tab button
tabButtons[0].classList.add("tab-active");
// Show the first tab content
tabContents[0].style.display = "block";

tabButtons.forEach((tabButton, index) => {
  tabButton.addEventListener("click", () => {
    // Remove "tab-active" class from all tab buttons
    tabButtons.forEach((button) => {
      button.classList.remove("tab-active");
    });
    // Add "tab-active" class to the current tab button
    tabButton.classList.add("tab-active");
    // Hide all tab contents
    tabContents.forEach((tabContent) => {
      tabContent.style.display = "none";
    });
    // Show the tab content for the current tab button
    tabContents[index].style.display = "block";
  });
});
</script>`}
              className=""
            ></DangerousHTML>
          </div>
        </div>
      </div>
    </section>
  )
}

Features.defaultProps = {
  image_src11: '/playground_assets/3-1200w.png',
  Heading1: 'Simply capture all your moves and clicks',
  rootClassName: '',
  text1: 'Create',
  image_alt1: 'image',
  Paragraph:
    "Creating interactive user manuals with Hinted is a breeze thanks to its visual editor. The editor's intuitive design allows for fast and easy creation of visually appealing, step-by-step guides that enhance the user experience.",
  image_alt11: 'image',
  image_src: '/playground_assets/1-1200w.png',
  image_alt: 'image',
  text2: 'Share',
  text3: 'Analyze',
  image_src1: '/playground_assets/2-1200w.png',
  Heading: 'Simply capture all your moves and clicks',
  Paragraph1:
    "Creating interactive user manuals with Hinted is a breeze thanks to its visual editor. The editor's intuitive design allows for fast and easy creation of visually appealing, step-by-step guides that enhance the user experience.",
}

Features.propTypes = {
  image_src11: PropTypes.string,
  Heading1: PropTypes.string,
  rootClassName: PropTypes.string,
  text1: PropTypes.string,
  image_alt1: PropTypes.string,
  Paragraph: PropTypes.string,
  image_alt11: PropTypes.string,
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  image_src1: PropTypes.string,
  Heading: PropTypes.string,
  Paragraph1: PropTypes.string,
}

export default Features
