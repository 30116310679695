import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar1 from '../components/navbar1'
import HubspotForm from '../components/hubspot-form'
import Footer from '../components/footer'
import './contact-us.css'

const ContactUs = (props) => {
  return (
    <div className="contact-us-container">
      <Helmet>
        <title>
          Contact-Us - HintEd Smart Manuals. Easily create interactive how-to
          guides
        </title>
        <meta
          name="description"
          content="Empower your digital training with HintEd. Create interactive user manuals, track user engagement, and analyze their understanding with ease. Enhance the user experience now."
        />
        <meta
          property="og:title"
          content="Contact-Us - HintEd Smart Manuals. Easily create interactive how-to guides"
        />
        <meta
          property="og:description"
          content="Empower your digital training with HintEd. Create interactive user manuals, track user engagement, and analyze their understanding with ease. Enhance the user experience now."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/59e42bed-5356-449a-8b07-54167279a332/30a33e3f-38bd-4c8b-94ad-cab6f9bb74ce?org_if_sml=1"
        />
      </Helmet>
      <Navbar1 rootClassName="navbar1-root-class-name"></Navbar1>
      <div className="contact-us-hero">
        <div className="contact-us-container1">
          <h1 className="contact-us-text">
            Discover our Enhanced Enterprise Capabilities
          </h1>
          <span className="contact-us-text1 section-description">
            Streamline Operations, Enhance Performance: Experience the Benefits
            of Hinted Smart Manuals Enterprise Plan.
          </span>
        </div>
        <div className="contact-us-container2">
          <HubspotForm rootClassName="hubspot-form-root-class-name"></HubspotForm>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default ContactUs
