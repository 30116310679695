import React from 'react'

import DangerousHTML from 'dangerous-html/react'
import PropTypes from 'prop-types'

import Gridmain from './gridmain'
import './tech-stack1.css'

const TechStack1 = (props) => {
  return (
    <section className={`tech-stack1-tech-stack ${props.rootClassName} `}>
      <div className="tech-stack1-container">
        <div className="tech-stack1-container1">
          <div className="">
            <h2 className="tech-stack1-text HeadingOne">{props.heading}</h2>
            <p className="tech-stack1-text1 Lead">
              <span className="tech-stack1-text2">
                Whatever your tech stack is - we&apos;ll get you covered!
              </span>
            </p>
            <p className="tech-stack1-text3 section-description">
              At HintEd, we understand the challenges of managing complex
              processes in modern enterprises. That&apos;s why we&apos;ve
              developed smart manuals that can capture interactive step-by-step
              guides for all your digital platforms, including both web and
              desktop applications. Our solution provides a centralized
              repository of process information, making it easier for employees
              to find and use the information they need. With HintEd, you can
              streamline processes and improve efficiency and productivity for
              all employees, no matter what digital platforms they use.
            </p>
          </div>
        </div>
        <Gridmain
          rootClassName="gridmain-root-class-name"
          className=""
        ></Gridmain>
      </div>
      <div className="">
        <DangerousHTML
          html={`<script>

const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    const grid = entry.target.querySelector('.stack-grid');

    if (entry.isIntersecting) {
      grid.classList.add('animate.grid');
	  return; // if we added the class, exit the function
    }

    // We're not intersecting, so remove the class!
    grid.classList.remove('animate.grid');
  });
});

observer.observe(document.querySelector('.tech-stack'));
</script>
`}
          className=""
        ></DangerousHTML>
      </div>
    </section>
  )
}

TechStack1.defaultProps = {
  rootClassName: '',
  heading: 'Runs wherever you want',
}

TechStack1.propTypes = {
  rootClassName: PropTypes.string,
  heading: PropTypes.string,
}

export default TechStack1
