import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <footer className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-content">
        <main className="footer-main-content">
          <div className="footer-content1">
            <header className="footer-main">
              <a
                href="https://hinted.me"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link"
              >
                <div className="footer-header">
                  <img
                    alt={props.Branding_alt}
                    src={props.Branding_src}
                    className="footer-branding"
                  />
                  <span className="footer-text">{props.Text}</span>
                </div>
              </a>
              <div className="footer-socials">
                <a
                  href="https://www.linkedin.com/company/hintedme/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footer-link1"
                >
                  <img
                    alt={props.LinkedIn_alt}
                    src={props.LinkedIn_src}
                    className="social"
                  />
                </a>
                <a
                  href="https://www.instagram.com/hinted.me/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footer-link2"
                >
                  <img
                    alt={props.Instagram_alt}
                    src={props.Instagram_src}
                    className="social"
                  />
                </a>
                <a
                  href="https://twitter.com/ed_hint"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footer-link3"
                >
                  <img
                    alt={props.Twitter_alt}
                    src={props.Twitter_src}
                    className="social"
                  />
                </a>
              </div>
            </header>
            <div className="footer-container">
              <div className="footer-category">
                <div className="footer-header1">
                  <span className="footer-header">{props.Text2}</span>
                </div>
                <div className="footer-links">
                  <div className="footer-container1">
                    <a
                      href={props.link_Text}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="footer-link"
                    >
                      {props.Text3}
                    </a>
                  </div>
                  <div className="footer-container2">
                    <a
                      href={props.link_Text2}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="footer-link"
                    >
                      {props.Text5}
                    </a>
                  </div>
                  <div className="footer-container3">
                    <a
                      href={props.link_Text1}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="footer-link"
                    >
                      {props.Text4}
                    </a>
                  </div>
                </div>
              </div>
              <div className="footer-category1">
                <div className="footer-header2">
                  <span className="footer-header">{props.Text61}</span>
                </div>
                <div className="footer-links1">
                  <div className="footer-container4">
                    <a
                      href={props.link_Text31}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="footer-text06 footer-link"
                    >
                      {props.Text71}
                    </a>
                  </div>
                  <div className="footer-container5">
                    <a
                      href={props.link_Text41}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="footer-text07 footer-link"
                    >
                      {props.Text81}
                    </a>
                  </div>
                  <div className="footer-container6">
                    <a
                      href={props.link_Text3}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="footer-text08 footer-link"
                    >
                      {props.Text811}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="footer-copyright">
            <span className="footer-text09">{props.text1}</span>
          </section>
        </main>
        <main className="footer-subscribe"></main>
        <section className="footer-copyright1">
          <span className="footer-text10">{props.text}</span>
        </section>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  link_Text3: 'https://hinted.me/privacypolicy',
  Text811: 'Privacy Policy',
  link_Text31: 'https://hinted.me/about',
  Text1: '->',
  rootClassName: '',
  Text81: 'Careers',
  Text71: 'About',
  Text4: 'Employee Training',
  LinkedIn_src: '/playground_assets/linkedin-200h.png',
  link_Text41: 'https://hinted.freshteam.com/jobs',
  link_Text2: 'https://hinted.me/onboarding',
  Notice:
    'By subscribing to our newsletter you agree with our Terms and Conditions.',
  Instagram_src: '/playground_assets/instagram-200h.png',
  Branding_src: '/playground_assets/hinted-logo_10_04_all_white.svg',
  text1: '© 2023 HintEd Company. All Rights Reserved.',
  Text3: 'Digital Adoption\n',
  LinkedIn_alt: 'image',
  Branding_alt: 'image',
  link_Text411: 'https://hinted.freshteam.com/jobs',
  Text: 'The Digital Adoption Platform',
  text: '© 2023 HintEd Company. All Rights Reserved.',
  textinput_placeholder: 'Enter your email',
  Twitter_src: '/playground_assets/twitter-200h.png',
  Heading: 'Subscribe to our newsletter',
  Text5: 'User Onboarding',
  Twitter_alt: 'image',
  link_Text1: 'https://hinted.me/training',
  Text2: 'Solutions',
  Instagram_alt: 'image',
  link_Text: 'https://hinted.me/dap',
  Text61: 'Company',
}

Footer.propTypes = {
  link_Text3: PropTypes.string,
  Text811: PropTypes.string,
  link_Text31: PropTypes.string,
  Text1: PropTypes.string,
  rootClassName: PropTypes.string,
  Text81: PropTypes.string,
  Text71: PropTypes.string,
  Text4: PropTypes.string,
  LinkedIn_src: PropTypes.string,
  link_Text41: PropTypes.string,
  link_Text2: PropTypes.string,
  Notice: PropTypes.string,
  Instagram_src: PropTypes.string,
  Branding_src: PropTypes.string,
  text1: PropTypes.string,
  Text3: PropTypes.string,
  LinkedIn_alt: PropTypes.string,
  Branding_alt: PropTypes.string,
  link_Text411: PropTypes.string,
  Text: PropTypes.string,
  text: PropTypes.string,
  textinput_placeholder: PropTypes.string,
  Twitter_src: PropTypes.string,
  Heading: PropTypes.string,
  Text5: PropTypes.string,
  Twitter_alt: PropTypes.string,
  link_Text1: PropTypes.string,
  Text2: PropTypes.string,
  Instagram_alt: PropTypes.string,
  link_Text: PropTypes.string,
  Text61: PropTypes.string,
}

export default Footer
